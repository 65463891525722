
import PolicyInfo from "@/components/User/PolicyInfo.vue";
import insuredSchemas from "@/forms/shared/changeRequestInsuredSchema";
import { getBillingChangeRequestDefaults } from "@/helpers/getBillingChangeRequestDefaults";
import ChangeRequestFileUpload from "@/components/AttachmentUploader/ChangeRequestFileUpload.vue";
import {
  billingChangeRequestMapActions,
  billingChangeRequestMapGetters,
  billingChangeRequestMapMutations,
  billingChangeRequestMapState
} from "@/store/modules/billingchangerequest";
import * as types from "@/store/mutation-types";
import {
  checkObjectForFalsyValues,
  hasValidAddress,
  validateFields
} from "@/helpers";
import { propertyChangeRequestMapState } from "@/store/modules/propertychangerequest";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { get, cloneDeep, set } from "lodash";
import { FormBlock } from "@/components/FormBuilder/types";
import { defaultAddressStructure } from "@/helpers/defaultObjects";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";

interface IData {
  loading: boolean;
  loadingText: string;
  error: string;
  message: string;
  isAdmin: boolean;
  hasValidCredentials: boolean;
  disablePolicyInfoComponent: boolean;
  myAccordionCategory: any[];
  activeNames: any[];
  touchedContent: {
    visible: boolean;
    name: string;
    ref: string;
  };
  consistencyRadioButton: string;
  EndorsementsLimitsTextAreaLimitNumber: string;
  BillMortgageRadioButton: string;
  MortgageRadioButton: string;
  MortgageTextAreaLimitNumber: string;
  CancellationReasonRadioButton: string;
  CoverageLimitsTextAreaLimitNumber: string;
  EndorsementsLimitsRadioButton: string;
  agentCertifyCheckBox: boolean;
  showForeclosureAlert: boolean;
  billingChangeRequestId: string;
  insuredSchemas: FormBlock[];
  modalMessage: string;
  modalDescription: string;
  modalButtonYesClick: boolean;
  insuredInfo: Record<string, any>;
  insuredKeys: string[];
  errorMessage: string;
}

export default VueWithMixins(ChangeRequestMixin).extend({
  name: "AddBillingChangeRequest",
  components: {
    PolicyInfo,
    ChangeRequestFileUpload,
    CustomAlert,
    ChangeRequestInsured: () =>
      import("@/components/User/ChangeRequestInsured.vue")
  },
  data(): IData {
    return {
      loading: false,
      showForeclosureAlert: true,
      error: "",
      message: "",
      BillMortgageRadioButton: "",
      CancellationReasonRadioButton: "",
      MortgageRadioButton: "",
      CoverageLimitsTextAreaLimitNumber: "",
      EndorsementsLimitsRadioButton: "",
      isAdmin: false,
      hasValidCredentials: false,
      MortgageTextAreaLimitNumber: "",
      consistencyRadioButton: "",
      disablePolicyInfoComponent: false,
      agentCertifyCheckBox: false,
      insuredSchemas,
      touchedContent: {
        visible: false,
        name: "",
        ref: ""
      },
      billingChangeRequestId: "",
      EndorsementsLimitsTextAreaLimitNumber: "",
      loadingText: "",
      activeNames: ["1", "2"],
      myAccordionCategory: [
        {
          visible: false,
          name: "Coverage/Limits",
          ref: "coverage"
        },
        {
          visible: false,
          name: "Document Upload",
          ref: "document"
        }
      ],
      modalMessage: "",
      modalDescription: "",
      modalButtonYesClick: false,
      insuredInfo: {},
      insuredKeys: ["firstName", "lastName"],
      errorMessage: ""
    };
  },
  created() {
    this.setEdit(getBillingChangeRequestDefaults({}));
    this.editingField = this.editField;
  },
  destroyed() {
    this.setState({ key: "editing", value: null });
  },
  methods: {
    ...billingChangeRequestMapActions([
      "createBillingChangeRequest",
      "updateBillingChangeRequest"
    ]),
    ...billingChangeRequestMapMutations({
      editField: types.SET_EDIT_BILLING_CHANGE_REQUEST,
      setEdit: types.SET_EDIT,
      setState: types.SET_STATE
    }),
    editPolicyInsuredInfo() {
      this.billingChangeRequestId = "";
      this.insuredInfo.changeRequestMade = false;
      this.insuredInfo.isExempted = false;
      this.disablePolicyInfoComponent = false;
      const insuredKey = [
        "lastName",
        "firstName",
        "insuredAddress.houseNumber",
        "insuredAddress.streetName",
        "insuredAddress.zipCode",
        "insuredAddress.city",
        "insuredAddress.state"
      ];
      for (let i = 0; i < insuredKey.length; i++) {
        this.handleChangeRequestCreation({
          fieldKey: `data.insured.${insuredKey[i]}`,
          value: ""
        });
      }
    },
    async doUpdate() {
      try {
        let updateData: any = cloneDeep(this.editing);
        delete updateData.data?.agentInfo;

        let updatedFields = { ...updateData, submittedBy: this.submittedBy };
        if (this.attachmentIds && this.attachmentIds.length) {
          set(updatedFields, "attachments", this.attachmentIds);
        }

        await this.updateBillingChangeRequest({
          id: this.billingChangeRequestId,
          update: updatedFields
        });

        this.goBackToListView("/billingchangerequests");
        this.$appNotifySuccess(`Cancellation Change Request Submitted`);
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    setMailingAndLocAddresses(addressesArray: any[]) {
      if (addressesArray.length) {
        const insuredAddressKey = [
          "houseNumber",
          "streetName",
          "zipCode",
          "city",
          "state"
        ];
        for (let m = 0; m < addressesArray.length; m++) {
          for (let i = 0; i < insuredAddressKey.length; i++) {
            const key = m == 0 ? "insuredAddress" : "location";

            this.handleChangeRequestCreation({
              fieldKey: `data.insured.${key}.${insuredAddressKey[i]}`,
              value: get(addressesArray[m], `${insuredAddressKey[i]}`, "")
            });
          }
        }
      }
    },
    attachmentUploadHandler(data: any) {
      this.uploadedFile = [...data, ...this.uploadedFile];
      this.temporalMapFileData(data, this.editing);
    },
    async onToolbarItemSelected(action: string): Promise<void> {
      switch (action) {
        case "create":
          this.save();
          break;
        case "cancel":
          this.goBackToListView("/billingchangerequests");
          break;
        case "saveChangeRequest":
          await this.doCreate();
          break;
        case "submitChangeRequest":
          await this.doSubmit();
          break;
      }
    },

    updateIsValid() {
      this.hasValidCredentials = this.$refs
        ? validateFields(this.$refs)
        : false;
    },
    Submit() {
      this.setBillingRequestAdditionalData("Submit");
      this.doCreate();
    },
    save() {
      this.setBillingRequestAdditionalData("save");
      this.doCreate();
    },
    async sendFormData() {
      try {
        if (this.billingChangeRequestId) {
          return await this.doUpdate();
        }
        let updatedFields = { ...this.editing };
        if (this.attachmentIds && this.attachmentIds.length) {
          set(updatedFields, "attachments", this.attachmentIds);
        }
        const response = await this.createBillingChangeRequest(updatedFields);
        this.uploadedFile = [];

        const { id, submittedBy } = response;
        this.billingChangeRequestId = id;
        this.submittedBy = submittedBy;
        this.$appNotifySuccess(`Cancellation Change Request Saved`);
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async doCreate(): Promise<void> {
      if (Object.keys(this.editing as any).length === 0) {
        return;
      }
      try {
        this.loading = true;
        if (!this.uploadedFile.length) {
          return await this.sendFormData();
        }
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        if (response.length) {
          const attachmentIds = response.map((file: any) => file._id);
          this.attachmentIds = [...this.attachmentIds, ...attachmentIds];
        } else {
          this.attachmentIds = [...this.attachmentIds, response._id];
        }

        this.mapFileData(response, this.editing);
        await this.sendFormData();
      } catch (error) {
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    async doSubmit() {
      if (!this.CancellationReasonRadioButton) {
        this.error = "Please select a cancellation reason";
        return;
      }
      this.setBillingRequestAdditionalData("Submit");
      await this.doCreate();
    },
    setBillingRequestAdditionalData(option = "save") {
      const userId = this.$getCurrentUser._id;
      let status = "Not Submitted";
      if (option == "Submit") {
        status = "Submitted";
        this.editField({ key: "submittedOn", value: new Date() });
      }
      this.editField({ key: "status", value: status });
      this.editField({ key: "userId", value: userId });
    }
  },
  computed: {
    ...billingChangeRequestMapState(["editing", "makingApiRequest"]),
    ...billingChangeRequestMapGetters(["getBillingChangeRequestsFromStore"]),
    ...propertyChangeRequestMapState({
      loadingInsuredInfo: "makingApiRequest"
    }),
    secondaryToolbar(): any {
      if (this.editing) {
        return {
          text: "Actions",
          key: "actions",
          subItems: [{ title: "Cancel", command: "cancel" }]
        };
      }
      return undefined;
    },
    hasValidCancellationReason(): boolean {
      return this.CancellationReasonRadioButton === "other"
        ? Boolean(this.editing?.data.billing?.other)
        : true;
    },
    hasValidBillingAddress(): boolean {
      const insuredAddress = this.editing?.data.insured.insuredAddress;
      return !hasValidAddress(
        insuredAddress || defaultAddressStructure,
        insuredAddress?.isPOBoXFormat
      );
    },
    hasValidCancellationFields(): boolean {
      return Boolean(
        !this.hasValidCancellationReason ||
          this.checkForEmptyRequiredFields ||
          !(
            this.editing?.data?.insured &&
            Object.keys(this.editing?.data?.insured).includes(
              "isInsuredAddressDifferent"
            )
          )
      );
    },
    disablePrimaryButton(): boolean {
      return (
        this.hasValidBillingAddress ||
        this.hasValidCancellationFields ||
        this.policyIsLapsedAndHasNoAttachments(this.editing)
      );
    },
    primaryToolbar(): any {
      if (
        this.insuredInfo &&
        this.insuredInfo.changeRequestMade &&
        !this.billingChangeRequestId
      ) {
        return {
          text: "Save",
          key: "saveChangeRequest",
          disabled: this.disablePrimaryButton,
          loading: this.loading
        };
      } else if (this.billingChangeRequestId) {
        return {
          text: "Submit",
          key: "submitChangeRequest",
          disabled: this.disablePrimaryButton,
          loading: this.loading
        };
      }
      return null;
    },
    checkForEmptyRequiredFields(): boolean {
      if (this.editing && this.editing.data && this.editing.data.billing) {
        const billing = get(this.editing.data, "billing", {});
        return checkObjectForFalsyValues(billing, "agentCertify");
      }
      return true;
    }
  }
});
